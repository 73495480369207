///react se encarga de crear interfaces
import React from "react";
import "./App.css";
import tasks from "./sample/tasks.json";
// aqui agregamos los componentes
import Tasks from "./components/Tasks";
import TaskForm from "./components/TaskForm";

import Post from "./components/Post";

console.log(tasks);

class App extends React.Component {
  state = {
    tasks: tasks
  };

  addTask = (title, description) => {
    const newTask = {
      title : title,
      description: description,
      id: this.state.tasks.length + 1
    };

    this.setState({ tasks: [...this.state.tasks, newTask] });
  };

  deleteTask = id => {
    const newTasks = this.state.tasks.filter(task => task.id !== id);

    this.setState({ tasks: newTasks });
    console.log(newTasks);
  };

  checkDone = id => {
   const newTasks= this.state.tasks.map(task => {
      if (task.id === id) {
        task.done = !task.done;
      }
      return task;
    });
    this.setState ({tasks: newTasks})
  };

  render() {
    return (
      <div>
        <TaskForm addTask={this.addTask} />
        <Tasks tasks={this.state.tasks} 
        deleteTask={this.deleteTask} 
        checkDone = {this.checkDone}
        />
        <Post />
      </div>
    );
  }
}

export default App;

//PROPS se refiere a Properties o Propiedades
