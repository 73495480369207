import React, { Component } from "react";

export default class TaskForm extends Component {
  state = {
    title: "",
    description: ""
  };

  onSubmit = event => { 
    this.props.addTask(this.state.title, this.state.description)
    event.preventDefault();
    };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
  };



  render() {

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="title"
          type="text"
          placeholder="Write a Task" 
          value={this.state.title}
          onChange={this.onChange}
        />
        <br />
        <br />
        <textarea
          name="description"
          placeholder="Write a description"
          value={this.state.description}
          onChange={this.onChange}
        />
        <br />
        <button type="sumbit">Add a TASK</button>
      </form>
    );
  }
}
