import React, { Component } from "react";

export default class Post extends Component {
  async componenDidMount() {
    const res =await fetch("https://jsonplaceholder.typicode.com/posts");
    console.log(res);   
  }

  render() {
    return <div>


<h1></h1>

    </div>;
  }
}
